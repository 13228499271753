<template>
  <div class="mt-1 mx-3">
    <panel> </panel>
  </div>
</template>

<script>
import Panel from "@/components/profile/emessage/panel/index.vue";
export default {
  props: {
    msg: String,
  },
  components: {
    Panel,
  },
  created() {},
};
</script>
